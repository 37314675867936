import {inject} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {CompanyService} from './company.service';
import {AppState} from '../app-state';

export const companyDetailResolver = async (route: ActivatedRouteSnapshot) => {
  const companyService = inject(CompanyService);
  const state = inject(AppState);
  const {companyId, siteId} = route.params;

  const company = companyId ? await companyService.detail(companyId) : undefined;
  const site = company?.site.find(({id}) => id == siteId);
  state.selectCompanySite(company, site);
  return {company, site};
};
